<template>
  <div class="o-my-account-orders">
    <div class="orders-head" v-if="isDesktop && !isShowOrder">
      <div class="orders-head__title">
        {{ $t('My purchases') }}
      </div>
    </div>
    <div class="orders-wrap">
      <div v-if="!ordersLoaded" class="orders-loader">
        <SfLoader />
      </div>
      <div v-else-if="!isViewStateList" class="orders-empty">
        <div class="orders-empty__text">
          <div class="orders-empty__main-text">
            {{ $t('You have not bought anything yet') }}
          </div>
          <div class="orders-empty__description">
            {{ $t('We recommend viewing popular products from categories') }}
          </div>
        </div>
        <div class="orders-empty__action">
          <SfButton
            class="sf-button--primary"
            data-transaction-name="Orders - Go To Home Page"
            @click="goToHomePage"
          >
            {{ $t("Go to Main page") }}
          </SfButton>
        </div>
      </div>
      <div v-else>
        <div v-show="!isShowOrder" class="orders-list-wrap">
          <div class="orders-list">
            <SfOTabs :open-tab="1" :tab-links="tabLinks">
              <SfTab>
                <div class="orders-tab">
                  <MAccountOrdersList
                    :orders="preparedOnlineOrders"
                    @showOrder="onShowOrder"
                    orders-type="online"
                  />
                </div>
              </SfTab>
<!--              <SfTab>-->
<!--                <div class="orders-tab">-->
<!--                  <MAccountOrdersList-->
<!--                    :orders="preparedOfflineOrders"-->
<!--                    @showOrder="onShowOrder"-->
<!--                    orders-type="offline"-->
<!--                  />-->
<!--                </div>-->
<!--              </SfTab>-->
            </SfOTabs>
          </div>
        </div>
        <div v-show="isShowOrder">
          <NoSSR>
            <MAccountOrder
                v-if="Object.values(currentOrder).length"
                :order="currentOrder"
                @closeOrder="onCloseOrder(currentOrder.item_id)"
            />
          </NoSSR>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import MAccountOrdersList from 'theme/components/molecules/m-account-orders-list';
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'
import SfOTabs from 'src/themes/varus/components/storefront-override/SfOTabs';
import { SfButton } from '@storefront-ui/vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { prepareOrder } from 'theme/helpers/orders';
import NoSSR from 'vue-no-ssr';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'OMyAccountOrders',
  components: {
    MAccountOrdersList,
    MAccountOrder: () => process.browser ? import('theme/components/molecules/m-account-order') : null,
    SfButton,
    SfOTabs,
    SfLoader,
    NoSSR
  },
  mixins: [DeviceType],
  data () {
    return {
      isShowOrder: false,
      currentOrder: {},
      scrollOptions: {
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      }
    }
  },
  computed: {
    ...mapState({
      ordersOnline: state => state.user.ordersOnline,
      ordersOffline: state => state.user.ordersOffline
    }),
    ...mapGetters({
      ordersHistory: 'user/getOrdersHistory',
      ordersLoaded: 'user/getOrdersHistoryLoaded',
      lastOrder: 'user/lastOrder'
    }),
    totalOnline () {
      return this.ordersOnline.total_online || 0;
    },
    totalOffline () {
      return this.ordersOffline.total_offline || 0;
    },
    currentTotalOnline () {
      return this.ordersOnline.current_total_orders || 0;
    },
    currentTotalOffline () {
      return this.ordersOffline.current_total_orders || 0;
    },
    totalAll () {
      return this.totalOnline + this.totalOffline || 0;
    },
    preparedOnlineOrders () {
      return this.prepareOrders(this.ordersOnline.orders);
    },
    preparedOfflineOrders () {
      return this.prepareOrders(this.ordersOffline.orders);
    },
    isViewStateList () {
      return this.totalAll > 0
    },
    tabLinks () {
      return [
        // { title: 'All', number: this.totalAll },
        { title: 'Online orders', number: this.currentTotalOnline }
        // { title: 'Shopping in stores', number: this.currentTotalOffline }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        if (to.query?.entityId) {
          this.onShowOrderByLink(to.query.entityId)
        }
      }
    }
  },
  beforeMount () {
    this.handlerUpdateOrdersHistory()
  },
  methods: {
    ...mapActions({
      getLastOrder: 'user/getLastOrder',
      loadOnlineOrdersHistory: 'user/loadOnlineOrdersHistory',
      loadOfflineOrdersHistory: 'user/loadOfflineOrdersHistory',
      loadOfflineOrderItems: 'user/loadOfflineOrderItems'
    }),
    prepareOrders (orders) {
      return orders?.map(order => prepareOrder(order)) || [];
    },
    goToHomePage () {
      this.$router.push(this.localizedRoute(formatRegionUrl('/')));
    },
    async onShowOrder (order = {}) {
      if (order.type === 'offline') {
        const result = await this.loadOfflineOrderItems({ cheque_id: order.cheque_id })
        order.items = result.items
      }
      this.currentOrder = order
      this.isShowOrder = true
    },
    async onShowOrderByLink () {
      // todo:: use API to get order by id
      if (!this.lastOrder) {
        await this.getLastOrder({ pageSize: 1, currentPage: 1, status: 'online' })
      }

      this.currentOrder = this.lastOrder ? prepareOrder(this.lastOrder) : {}
      this.isShowOrder = true
    },
    onCloseOrder () {
      this.currentOrder = {};
      this.isShowOrder = false;

      // setTimeout(() => {
      //   const orderElement = this.$refs.orderList.$el.querySelector(`[data-order-id='${orderId}']`);
      //   if (orderElement) orderElement.scrollIntoView(this.scrollOptions);
      // }, 0)
    },
    async handlerUpdateOrdersHistory () {
      try {
        await this.loadOnlineOrdersHistory({})
        // await this.loadOfflineOrdersHistory({})
      } catch (e) {}
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';
@import "~theme/css/px2rem";

.o-my-account-orders {
  padding: 10px;

  @media (min-width: $tablet-min) {
    padding: 0;
  }

  .orders-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet-max){
      padding: var(--spacer-7) var(--spacer-25);
    }

    @media (min-width: $desktop-min) {
      padding-bottom: var(--spacer-30);
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: normal;
    }
  }

  .orders-wrap {
    position: relative;
    background-color: var(--white);
    box-sizing: border-box;

    .orders-loader {
      min-height: 505px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .orders-empty {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;

      @include font-icon(var(--icon-cart));

      &:before {
        font-size: var(--font-size-30);
        color: var(--black);
        background: var(--white);
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border-radius: 50%;
        border: 25px solid var(--light-gray);
      }

      @media (max-width: $mobile-max) {
        padding: var(--spacer-70) var(--spacer-10);
      }

      @include for-tablet {
        padding: var(--spacer-100) var(--spacer-30);
      }

      @include for-desktop {
        padding: var(--spacer-100) var(--spacer-50);
      }

      &__text {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        text-align: center;
        line-height: normal;
        margin-top: var(--spacer-35);
        color: var(--dark-gray);

        @media (max-width: $mobile-max) {
          margin-top: var(--spacer-40);
        }
      }

      &__main-text {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        text-align: center;
        line-height: normal;
      }

      &__description {
        margin-top: var(--spacer-15);
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        max-width: 423px;
        text-align: center;
        line-height: normal;

        @media (max-width: $mobile-max) {
          max-width: 309px;
        }
      }

      &__action {
        margin-top: var(--spacer-40);
        width: 100%;

        @media (max-width: $mobile-max) {
          margin-top: var(--spacer-30);
        }

        button {
          font-size: var(--font-size-18);
          line-height: var(--font-size-24);
          margin: 0 auto;

          @media (max-width: $mobile-max) {
            --button-width: 100%;
          }

          @media (min-width: $tablet-min) {
            --button-width: 247px;
          }
        }
      }
    }

    .orders-list-wrap {
      min-height: 555px;
      box-sizing: border-box;

      @media (max-width: $mobile-max) {
        padding: var(--spacer-15) 0;
      }

      @include for-tablet {
        padding: var(--spacer-40) var(--spacer-40) 0;
      }

      @include for-desktop {
        padding: var(--spacer-30) var(--spacer-50) 0;
      }

      ::v-deep {
        .sf-tabs {
          &__title {
            display: none;
          }

          &__content__tab {
            padding: 0;
          }

          /*&__content {
            width: 100%;

            &__tab {
              padding: 0;
            }
          }*/

          --tabs-title-z-index: 1;
          --tabs-content-order: 1;
          --tabs-title-flex: 0 0 auto;
          --tabs-title-margin: 0 var(--spacer-lg) -2px 0;
          --tabs-title-padding: 0 0 15px;
          --tabs-title-color: var(--c-text-muted);
          --tabs-title-font-size: var(--h4-font-size);
          --tabs-content-tab-padding: var(--spacer-base) 0;
          --tabs-chevron-display: none;
          --tabs-title-font-line-height: var(--font-size-19);
        }
      }

      .orders-tab {
        margin-top: var(--spacer-20);

        @media (max-width: $mobile-max) {
          margin-top: var(--spacer-15);
        }
      }
    }
  }

  ::v-deep {
    .m-products-add-to-cart {
      .a-loading-spinner {
        position: fixed;
      }
    }
  }
}
::v-deep {
  .tabs-header__title {
    padding: 0 0 px2rem(15);
  }
}
</style>
